<span
  class="tw-font-sans tw-leading-normal"
  *ngrxLet="displayLevel ?? level as computedDisplayLevel"
  [ngClass]="{
    'tw-text-white': color === 'bright',
    'tw-text-black': color === 'dark',
    'tw-text-[40px] tw-font-normal': computedDisplayLevel === 1,
    'tw-text-[32px] tw-font-normal': computedDisplayLevel === 2,
    'tw-text-[24px] tw-font-normal': computedDisplayLevel === 3,
    'tw-text-[20px] tw-font-normal': computedDisplayLevel === 4,
    'tw-text-[18px] tw-font-normal': computedDisplayLevel === 5,
    'tw-text-[16px] tw-font-bold': computedDisplayLevel === 6,
  }"
>
  <ng-container
    *ngrxLet="
      'tw-m-0 tw-font-[inherit] tw-leading-[inherit] tw-text-[inherit] [font-size:inherit!important] [font-weight:inherit!important]' as commonClasses
    "
  >
    @switch (level) {
      @case (1) {
        <h1 [ngClass]="commonClasses">
          <ng-container *ngTemplateOutlet="contentTemplate" />
        </h1>
      }

      @case (2) {
        <h2 [ngClass]="commonClasses">
          <ng-container *ngTemplateOutlet="contentTemplate" />
        </h2>
      }

      @case (3) {
        <h3 [ngClass]="commonClasses">
          <ng-container *ngTemplateOutlet="contentTemplate" />
        </h3>
      }

      @case (4) {
        <h4 [ngClass]="commonClasses">
          <ng-container *ngTemplateOutlet="contentTemplate" />
        </h4>
      }

      @case (5) {
        <h5 [ngClass]="commonClasses">
          <ng-container *ngTemplateOutlet="contentTemplate" />
        </h5>
      }

      @case (6) {
        <h6 [ngClass]="commonClasses">
          <ng-container *ngTemplateOutlet="contentTemplate" />
        </h6>
      }
    }
  </ng-container>
</span>

<ng-template #contentTemplate>
  <ng-content />
</ng-template>
